import { json, LoaderFunctionArgs } from "@remix-run/node";
import type { MetaFunction } from "@remix-run/node";
import {
  useLoaderData,
} from "@remix-run/react";
import { metaBuilder } from "~/shared/meta-builder";


export const meta: MetaFunction = () => {
  return metaBuilder({
    title: 'Recipes by Big Horn Olive Oil',
    description: 'Savory recipes to please your palate!',
    url: `/`,
    imageUrl: '/logo.png',
    imageAlt: 'Big Horn Olive Oil Logo',
  });
};

export const loader = async ({ params }: LoaderFunctionArgs) => {
  // TODO make these dynamic
  const title = 'Pappardelle Bolognese';
  const content = 'Savory Italian comfort food at its finest! Tender pappardelle noodles smothered in rich and meaty Bolognese sauce, served with a sprinkle of Parmesan cheese.';
  const url = '/recipe/pappardelle-bolognese';
  return json({ title, content, url });
};

export default function Index() {
  const { title, content, url } = useLoaderData<typeof loader>();

  return (
    <div
      className="pt-4 h-screen items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: "url('/images/homepage.jpg')" }}
    >
      <a href={url} className="mx-4">
        <div className="relative flex flex-col mx-16 items-center gap-4 p-6 bg-white bg-opacity-90 shadow-lg">
          <div className="bg-red-500 text-white px-4 py-2 rounded-full uppercase text-center">
            Recipe of the Day
          </div>
          <h1 className="text-3xl font-bold text-gray-800 text-center">
            {title}
          </h1>
          <p className="text-gray-700 text-center">
            {content}
          </p>
        </div>
      </a>
    </div>
  );
}
